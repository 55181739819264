import LocationIcon from "@components/icons/LocationIcon"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { sendCVURL, teamsURL } from "@lib/urls"
import { addRollWhatsapp, getWhatsAppURL, getWhatsBaseURL, gtagReportConversion, parseHTML } from "@lib/utils"
import { Link, graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./CareerInfo.scss"

const TeamCard = ({ team }) => {
  if (!team) return null
  return (
    <div className="team-card">
      <Link to={`${teamsURL + team.slug}/`} className="img-zoom">
        <img src={team.image?.url} alt="team-img" />
      </Link>
      <div className="team-content">
        <p className="name">{team.name}</p>
        <p className="designation">{team.designation}</p>
        <p className="orn">ORN: {team.license}</p>
      </div>
    </div>
  )
}

const CareerInfo = ({ career, team }) => {
  const { isTablet } = useDeviceMedia()

  const data = useStaticQuery(graphql`
    query {
      allStrapiGlobalModule {
        nodes {
          career_about {
            data {
              career_about
            }
          }
        }
      }
    }
  `)

  const aboutContent =
    data?.allStrapiGlobalModule?.nodes[0]?.career_about?.data?.career_about

  if (!career) return null

  const { title, location, job_details } = career
  return (
    <div className="career-info-wrap">
      <Container className="career-info-container">
        <div className="left-section">
          <div className="banner-section">
            <h1 className="title">
              <span>{title}</span>
            </h1>
            <p className="location">
              <LocationIcon blue />
              <span>{location}</span>
            </p>
          </div>
          <div className="career-content">
            {parseHTML(job_details?.data?.job_details)}
            {aboutContent && (
              <>
                <h3>About Provident</h3>
                {parseHTML(aboutContent)}
              </>
            )}
          </div>
          {isTablet && (
            <>
              <div className="divider" />
              <div className="team-section">
                <p className="heading">related contact</p>
                <TeamCard team={team} />
              </div>
            </>
          )}
        </div>
        <div className="right-section">
          <div className="contact-section">
            <div className="cta-section">
              <Link
                to={sendCVURL}
                className={!team?.phone ? "button button-orange bottom-fix-career" : "button button-orange" }
                state={{
                  jobPosition: title,
                }}
              >
                <span>{isTablet ? "Apply" : "Apply for this job"}</span>
                <MailIcon color="white" />
              </Link>
              {team && (
                <>
                  <a
                    href={`tel:${team.phone}`}
                    className="button button-gray"
                    onClick={() => gtagReportConversion(`tel:${team.phone}`)}
                  >
                    <span>Call</span>
                    <MobileIcon />
                  </a>
                  <a
                    href={getWhatsBaseURL(team?.phone)}
                    className="button button-gray"
                    target="_blank"
                    rel="noreferrer"
                    onClick={()=> addRollWhatsapp()}
                  >
                    <span>Whatsapp</span>
                    <WhatsAppIcon />
                  </a>
                </>
              )}
            </div>
            <TeamCard team={team} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CareerInfo
